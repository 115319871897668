import type { ClientInfo } from '~/types/auth';
import type { MappingData } from '~/types/mapping';

import { ofetch } from 'ofetch';
import { withQuery } from 'ufo';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) {
    const config = useRuntimeConfig();
    const client = useCookie('client-data');
    const { $sentry } = useNuxtApp();

    const query = to.query as Record<string, string>;
    const clientSecret = config.ssoClientSecret;
    const { state, client_id: clientID, redirect_uri: redirectURI } = query;

    if (!clientID || !redirectURI) return navigateTo('/authorize/error');

    try {
      type ResultData = Required<Pick<ClientInfo, 'uiConfig' | 'isThirdParty'>>;
      const result = await ofetch<ResultData>(SSO.v3.validateWebClient, {
        method: 'POST',
        body: { clientID, clientSecret, redirectUri: redirectURI },
      });

      const clientData = { ...result, state, clientID, redirectURI } as ResultData &
        Pick<ClientInfo, 'state' | 'clientID' | 'redirectURI' | 'mapping' | 'reference'>;

      if (query.mapping) {
        delete query.mapping;
        clientData.mapping = query as unknown as MappingData;
      }

      if (query.reference) clientData.reference = query.reference;

      client.value = JSON.stringify(clientData);
      if (clientID.toLowerCase().includes('wifi')) {
        return navigateTo({ query, path: '/authorize/form/wifi' });
      }

      const q = <Record<string, string>>{};
      const dest = from.path.includes('register') ? 'register' : 'login';
      if (dest == 'register' && query.reference) q.reference = query.reference;

      return navigateTo(withQuery(`/authorize/form/${dest}`, q), { external: true });
    } catch (e: any) {
      const err = e.data as APIErrorData;
      console.error(e);
      $sentry.withScope((scope) =>
        scope
          .setExtras({ clientID, redirectURI })
          .setTransactionName('[Middleware] SSO Authorize')
          .captureException(err || e)
      );

      return navigateTo(`/authorize/error?statusMessage=${err?.detail || ''}`);
    }
  }
});
